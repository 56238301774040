import * as Sentry from '@sentry/react';
import {renderApp} from '@ytsaurus-ui-platform/src/ui/render-app';
import axios from 'axios';
// @ts-ignore
import yt from '@ytsaurus/javascript-wrapper/lib/yt';
import {handleAuthError} from '@ytsaurus-ui-platform/src/ui/store/actions/global/index';
import {YT_UI_CLUSTER_HEADER_NAME} from '@ytsaurus-ui-platform/src/shared/constants/index';
import {tractoUIFactory} from './factoryOverrides';
import {getDefaultSentryOptions, sentrySrubbingPaths} from '../sentry';
import {Lang, configure} from '@nebius-ui/ava';
import '@nebius-ui/ava/style.css';
import '@ytsaurus-ui-platform/src/ui/entries/main.scss';
import {registerDialogControl} from '@gravity-ui/dialog-fields';
import {YTTokenInputControl} from '../containers/Dialogs/controls/YTTokenInputControl';

const appEnv = process.env.APP_ENV;

// ava configuration
configure({lang: Lang.En});

Sentry.init(
    getDefaultSentryOptions({
        dsn: 'https://23e2a14eb58d6e7df66e7dda6080da97@o4505906584485888.ingest.us.sentry.io/4507021978566656',
        enabled: appEnv === 'production',
        environment: appEnv,
        release: process.env.__SENTRY_RELEASE__,
        beforeSend: sentrySrubbingPaths,
    }),
);

registerDialogControl('yt-token-input', YTTokenInputControl);

renderApp(tractoUIFactory);

yt.subscribe('error', onError);
axios.interceptors.response.use(undefined, onAxiosError);

function onAxiosError(e: any) {
    onError(e);
    return Promise.reject(e);
}

function onError(e: any) {
    const isAuthError = axios.isAxiosError(e) && e.response?.status === 401;

    if (isAuthError) {
        handleAuthError({ytAuthCluster: e.response?.headers[YT_UI_CLUSTER_HEADER_NAME]});
    }
}
