import React from 'react';

import {useSelector} from 'react-redux';
import {getCluster, getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {YTTokenInput, YTTokenInputProps} from '../../../components/YTTokenInput/YTTokenInput';

type YTTokenInputControlProps = {
    value: string;
    onChange: (value: string) => void;
} & Pick<YTTokenInputProps, 'description'>;

export function YTTokenInputControl({value, onChange, description}: YTTokenInputControlProps) {
    const cluster = useSelector(getCluster);
    const user = useSelector(getCurrentUserName);

    return (
        <YTTokenInput
            value={value}
            user={user}
            cluster={cluster}
            description={description}
            onUpdate={onChange}
            gap={2}
        />
    );
}

YTTokenInputControl.getDefaultValue = () => {
    return '';
};

YTTokenInputControl.renderLabel = (field: {caption: string}) => {
    if (field.caption) {
        return field.caption;
    }

    return null;
};
